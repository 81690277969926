




















































































































































































































import { format } from 'date-fns';
import { DataCoreSvcFeedhdlFeedValidationOutput } from 'generated/data-core-svc';
import { Component, Vue } from 'vue-property-decorator';

import DataApiManager from '@/api/dataCoreApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiPageHeader from '@/components/ui/UiPageHeader.vue';

@Component({
  components: {
    UiButton,
    UiLoading,
    UiPageHeader,
    UiModal,
  },
})
export default class FeedDetail extends BaseVue {
  // Define interface for feed data to fix type errors
  public feed: {
    feedId: string;
    name: string;
    dataSourceId: string;
    cronExpression: string;
    status: string;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt?: string;
  } | null = null;

  public validationStatus: DataCoreSvcFeedhdlFeedValidationOutput | null = null;
  public feedRuns: any[] = [];
  public isLoading = false;
  public isStartFeedLoading = false;
  public isDeleting = false;
  public isLoadingFeedRuns = false;
  public showDeleteModal = false;
  public error: string | null = null;
  public dataSources: any[] = [];
  public isLoadingDataSource = false;

  get feedId(): string {
    return this.$route.params.id;
  }

  async mounted() {
    await Promise.all([this.loadFeedDetails(), this.loadDataSources(), this.loadRecentFeedRuns()]);
  }

  goBackToFeeds() {
    this.$router.push({ name: 'dataFeedsV2' });
  }

  async loadFeedDetails() {
    this.isLoading = true;
    this.error = null;
    try {
      const api = DataApiManager.getInstance();
      const resp = await api.handlersFeedhdlFeedHTTPHandlerGet(this.orgId, this.feedId, {
        withCredentials: true,
      });

      if (resp.status === 200) {
        this.feed = resp.data as any; // Cast to our interface
        // Also load validation status
        await this.loadValidationStatus();
      } else {
        this.error = `Error loading feed details: ${resp.statusText || 'Unknown error'}`;
      }
    } catch (err) {
      console.error('Error loading feed details:', err);
      this.error = `Error loading feed details: ${(err as Error).message || 'Unknown error'}`;
    } finally {
      this.isLoading = false;
    }
  }

  async loadValidationStatus() {
    try {
      const api = DataApiManager.getInstance();
      const resp = await api.handlersFeedhdlFeedHTTPHandlerGetFeedValidationStatus(this.orgId, this.feedId, {
        withCredentials: true,
      });

      if (resp.status === 200) {
        this.validationStatus = resp.data;
      }
    } catch (err) {
      console.error('Error loading validation status:', err);
      // Don't set error state, as this is a secondary request
    }
  }

  async loadDataSources() {
    this.isLoadingDataSource = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.handlersDatasourcehdlDataSourceHTTPHandlerList(this.orgId, undefined, undefined, {
        withCredentials: true,
      });
      if (resp.status === 200) {
        this.dataSources = resp.data.items || [];
      }
    } catch (err) {
      console.error('Error loading data sources:', err);
    } finally {
      this.isLoadingDataSource = false;
    }
  }

  getDataSourceName(dataSourceId: string): string {
    if (this.dataSources && this.dataSources.length > 0) {
      const item = this.dataSources.filter((item: { id: string }) => item.id === dataSourceId)[0];
      return item ? item.name : dataSourceId;
    }
    return dataSourceId;
  }

  standardizeFrequency(frequency: string): string {
    if (!frequency) {
      return 'Manual';
    }

    switch (frequency) {
      case '0 0 * * *':
        return 'Nightly';
      case '0 0 1 * *':
        return 'Monthly';
      case '0 0 * * 0':
        return 'Weekly';
      default:
        return frequency;
    }
  }

  async startSyncFeed() {
    if (!this.feed) return;

    this.isStartFeedLoading = true;
    try {
      const api = DataApiManager.getInstance();
      // First, initiate a manual run to get a feedRunId
      const initResp = await api.handlersFeedrunhdlFeedRunHandlerInitManualRun(this.orgId, this.feedId, {
        withCredentials: true,
      });

      if (initResp.status === 200 && initResp.data?.feedRunId) {
        const feedRunId = initResp.data.feedRunId;

        // Then directly start the sync without preview
        const syncResp = await api.handlersFeedrunhdlFeedRunHandlerStartSync(this.orgId, this.feedId, feedRunId, {
          withCredentials: true,
        });

        if (syncResp.status === 200) {
          this.showSuccessSnackbar(`Feed "${this.feed.name}" started successfully, Execution ID: ${feedRunId}`);
        } else {
          this.showErrorSnackbar(`Unable to start feed: ${syncResp.statusText || 'Unknown error'}`);
        }
      } else {
        this.showErrorSnackbar('Unable to initialize feed run, Please try again later');
      }
    } catch (err) {
      console.error('Error starting feed sync:', err);
      this.showErrorSnackbar(`Unable to start feed: ${(err as Error).message || 'Unknown error'}`);
    } finally {
      this.isStartFeedLoading = false;
    }
  }

  editFeed() {
    if (!this.feed) return;
    this.$router.push({ name: 'FeedEdit', params: { id: this.feedId } });
  }

  confirmDeleteFeed() {
    this.showDeleteModal = true;
  }

  async deleteFeed() {
    if (!this.feed) return;

    this.isDeleting = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.handlersFeedhdlFeedHTTPHandlerDelete(this.orgId, this.feedId, { withCredentials: true });

      if (resp.status === 200) {
        this.showSuccessSnackbar(`Feed "${this.feed.name}" deleted successfully`);
        this.showDeleteModal = false;
        // Navigate back to feeds list
        this.$router.push({ name: 'dataFeedsV2' });
      } else {
        this.showErrorSnackbar(`Error deleting feed: ${resp.statusText || 'Unknown error'}`);
      }
    } catch (err) {
      console.error('Error deleting feed:', err);
      this.showErrorSnackbar(`Error deleting feed: ${(err as Error).message || 'Unknown error'}`);
    } finally {
      this.isDeleting = false;
    }
  }

  async loadRecentFeedRuns() {
    this.isLoadingFeedRuns = true;
    try {
      const api = DataApiManager.getInstance();
      const resp = await api.handlersFeedrunhdlFeedRunHandlerListRuns(
        this.orgId,
        this.feedId,
        undefined, // filter
        undefined, // pageToken
        5, // pageSize - get the 5 most recent runs
        { withCredentials: true }
      );

      if (resp.status === 200 && resp.data.items) {
        this.feedRuns = resp.data.items;
      } else {
        console.error('Error loading feed runs:', resp.statusText || 'Unknown error');
      }
    } catch (err) {
      console.error('Error loading feed runs:', err);
      // Don't set error state, as this is a secondary request
    } finally {
      this.isLoadingFeedRuns = false;
    }
  }

  formatDate(dateString: string | undefined): string {
    if (!dateString) return 'N/A';
    try {
      return format(new Date(dateString), 'MMM d, yyyy h:mm a');
    } catch (e) {
      return dateString;
    }
  }

  statusClass(status: string): Record<string, boolean> {
    return {
      'tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full': true,
      'tw-bg-green-100 tw-text-green-800': status === 'COMPLETED',
      'tw-bg-yellow-100 tw-text-yellow-800': status === 'RUNNING',
      'tw-bg-red-100 tw-text-red-800': status === 'FAILED',
      'tw-bg-gray-100 tw-text-gray-800': ['COMPLETED', 'RUNNING', 'FAILED'].indexOf(status) === -1,
    };
  }
}
